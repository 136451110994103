import React, { ReactNode } from "react"
import { Seo } from "../gatsby-components"
import { HtmlTemplate } from "../templates/HtmlTemplate"
import { layout } from "../temp-content/layout"

const MdxWrapper = ({ children, metadata }: MdxWrapperProps) => {
  return (
    <>
      <Seo title={metadata.title} />
      <HtmlTemplate {...{ layout }}>{children}</HtmlTemplate>
    </>
  )
}

export interface MdxWrapperProps {
  children: ReactNode
  metadata: { title: string }
}

export default MdxWrapper
