import React, { ReactNode } from "react"
import { Layout, Section, Container, HtmlContent } from "../../components"
import { Template } from "../template.types"

export const HtmlTemplate = ({ layout, children }: HtmlTemplateProps) => {
  return (
    <Layout {...layout}>
      <Section as={Container}>
        <HtmlContent>{children}</HtmlContent>
      </Section>
    </Layout>
  )
}

export interface HtmlTemplateProps extends Template {
  children: ReactNode
}
